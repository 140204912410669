import React, { SetStateAction } from "react";
import DiscussionsOverview from "./DiscussionsOverview";
import styles from "./DiscussionsOverviewDesktopView.module.css";
import CloseIcon from "@mui/icons-material/Close";

interface DiscussionsOverviewDesktopViewProps {
  storyId: string;
  storyDateId: string;
  showDiscussionsView: React.Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

function DiscussionsOverviewDesktopView({
  storyDateId,
  storyId,
  showDiscussionsView,
  children,
}: DiscussionsOverviewDesktopViewProps) {
  return (
    <div className={styles.background}>
      <button
        onClick={() => showDiscussionsView(false)}
        className={styles.closeButton}
      >
        <CloseIcon />
      </button>
      <div className={styles.container}>
        <div className={styles.newsContainer}>{children}</div>
        <div className={styles.discussionsContainer}>
          <DiscussionsOverview
            display={true}
            storyId={storyId}
            storyDateId={storyDateId}
            showDiscussionsView={showDiscussionsView}
          />
        </div>
      </div>
    </div>
  );
}

export default DiscussionsOverviewDesktopView;
