export const isWithinRenderRange = (
    contentIndex: number,
    pageIndex: number,
    activeIndex: number,
    activePageIndex: number
  ): boolean => {
    if (pageIndex === activePageIndex) {
      return (
        contentIndex === activeIndex ||
        contentIndex === activeIndex - 1 ||
        contentIndex === activeIndex + 1
      );
    } else if (pageIndex === activePageIndex - 1 && activeIndex === 0) {
      return contentIndex === 4;
    } else if (pageIndex === activePageIndex + 1 && activeIndex === 4) {
      return contentIndex === 0;
    }
    return false;
  };