import React, { useEffect } from "react";
import styles from "./Welcome.module.css";
import Logo from "../components/icons/paper planes final_webpage transparent.png";
import { CircularProgress } from "@mui/material";
import { useHandleLogin } from "../auth/useHandleLogin";

const Welcome: React.FC = () => {
  const handleLogin = useHandleLogin();

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  return (
    <div className={styles.welcome_wrapper}>
      <div className={styles.upperBox}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>
      <CircularProgress color="inherit" />
    </div>
  );
};

export default Welcome;
