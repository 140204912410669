import styles from "./CreateNewGroup.module.css";
import { useState } from "react";
import { usePostNewGroup } from "../../customHooks/usePostNewGroup";
import AddContactsModal from "./AddContactsModal";
import { useNavigate } from "react-router-dom";
import Button from "../../components/elements/Button";
import { ReactComponent as ArrowBackIcon } from "../../components/icons/ArrowBackIcon.svg";
import { toast } from "react-toastify";
import { errorToastOptions } from "../../components/toastOptions";

function CreateNewGroup() {
  const [groupName, setGroupName] = useState<string>("");
  const [groupMembers, setGroupMembers] = useState<string[]>([]);
  const [addContactsView, setAddContactsView] = useState<boolean>(true);

  const navigate = useNavigate();

  const { mutate, isLoading: isLoadingPostGroup } = usePostNewGroup();

  const handleAddContactsClick = (selectedContacts: string[]) => {
    setGroupMembers(selectedContacts);
    setAddContactsView(false);
  };

  const onArrowBackClick = () => {
    navigate("/Community/Groups");
  };

  const handleCreateGroupButton = () => {
    mutate(
      {
        groupName: groupName,
        groupMembers: groupMembers,
      },
      {
        onError: () => {
          toast.error(
            "Something went wrong. We couldn't create the group.",
            errorToastOptions
          );
        },
      }
    );
  };

  return (
    <>
      <AddContactsModal
        onArrowBackClick={onArrowBackClick}
        currentMembers={[]}
        handleAddClick={handleAddContactsClick}
        addButtonText="Add contacts"
        display={addContactsView}
      />

      <div className={styles.background}>
        <div className={styles.groupNameContainer}>
          <div
            className={styles.arrowBack}
            onClick={() => setAddContactsView(true)}
          >
            <ArrowBackIcon />
          </div>

          <div className={styles.content}>
            <input
              placeholder="Group name..."
              className={styles.input}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <Button
              handleClick={handleCreateGroupButton}
              disabled={isLoadingPostGroup}
              width="250px"
              buttonText={
                isLoadingPostGroup ? "Loading..." : "Create new group"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewGroup;
