import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import styles from "./RoundMoreButton.module.css";

interface RoundMoreButtonProps {
  size?: number;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
}

function RoundMoreButton({ size, handleClick }: RoundMoreButtonProps) {
  const sizePixels: string = size ? size + "px" : "";

  return (
    <button
      style={{ height: sizePixels, width: sizePixels }}
      className={styles.button}
      onClick={handleClick}
    >
      <MoreHorizIcon />
    </button>
  );
}

export default RoundMoreButton;
