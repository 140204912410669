import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";

export const useMarkNewsAsRead = () => {
  const markAsRead = async ({
    storyDateId,
    storyId,
  }: {
    storyDateId: string;
    storyId: string;
  }) => {
    const APIUrl = `/usertimeline/${storyDateId}/${storyId}/read`;
    try {
      await apiClient.post(APIUrl);
    } catch (error) {
      throw error;
    }
  };

  return useMutation(markAsRead);
};
