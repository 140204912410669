import styles from "./ContactsList.module.css";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { UserContactResponseType } from "../../types/UserContactResponseType";
import { useInView } from "react-intersection-observer";
import Contact from "../../components/elements/Contact";
import { useGetMyContacts } from "../../customHooks/useGetMyContacts";
import SupportButton from "./SupportButton";
import { useQueryClient } from "react-query";
import { RecipientType } from "../../discussions/types/RecipientType";
import { useNavigate } from "react-router-dom";

interface ContactsListProps {
  followType: "following" | "followers";
}

function ContactsList({ followType }: ContactsListProps) {
  const endpoint =
    followType.toLowerCase() === "followers" ? "supporting" : "supports";
  const type =
    followType.toLowerCase() === "followers" ? "Supporting" : "Supporters";

  const { ref: bottomLineRef, inView: bottomLineVisible } = useInView();

  const {
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
  } = useGetMyContacts({
    endpoint: endpoint,
    type: type,
  });

  useEffect(() => {
    if (bottomLineVisible && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [bottomLineVisible, isFetchingNextPage, hasNextPage, fetchNextPage]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const navigateToUserProfile = (userId: string) => {
    navigate(`/User/${userId}`);
  };

  const updateQuery = async (contactId: string, isSupported: boolean) => {
    await queryClient.cancelQueries(["contacts", type]);
    const contactsQueryData = queryClient.getQueryData<{
      pages: UserContactResponseType[][];
    }>(["contacts", type]);

    if (contactsQueryData) {
      const flatContacts = contactsQueryData.pages.flat();
      const contactToUpdate = flatContacts.find(
        (contact) => contact.Id === contactId
      );

      if (contactToUpdate) {
        queryClient.setQueryData<
          | {
              pageParams: unknown[];
              pages: UserContactResponseType[][];
            }
          | undefined
        >(["contacts", type], (prevData) => {
          if (prevData) {
            const updatedPages = prevData.pages.map((page) =>
              page.map((contact) =>
                contact.Id === contactId
                  ? { ...contact, IsSupported: !isSupported }
                  : contact
              )
            );

            return {
              ...prevData,
              pages: updatedPages,
            };
          }
          return undefined;
        });
      }
    }
  };

  if (isError) {
    return (
      <div className={styles.contactsListContainer}>
        <div className={styles.information}>
          <p> Something went wrong. Please try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.contactsListContainer} key={type}>
        {data && data.pages[0]?.length === 0 ? (
          <div className={styles.information}>
            {followType.toLocaleLowerCase() === "followers"
              ? "You dont' have any followers yet"
              : "You don't follow anyone yet"}
          </div>
        ) : (
          data?.pages.map((page) =>
            page?.map((contact: UserContactResponseType) => (
              <Contact
                Name={contact.Name}
                UserName={contact.UserName}
                ContactType={RecipientType.User}
                Avatar={contact.Avatar}
                onClick={() => navigateToUserProfile(contact.Id)}
                key={contact.Id}
              >
                {
                  <SupportButton
                    contactId={contact.Id}
                    isSupported={contact.IsSupported}
                    onSuccess={updateQuery}
                    key={`btn${contact.Id}`}
                  />
                }
              </Contact>
            ))
          )
        )}

        {isLoading || isFetchingNextPage ? (
          <div
            className={`${styles.loading} ${
              isFetchingNextPage && styles.loadingBottom
            }`}
          >
            <CircularProgress />
          </div>
        ) : null}
        <div ref={bottomLineRef} className={styles.bottomInView}></div>
      </div>
    </>
  );
}

export default ContactsList;
