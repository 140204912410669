import { RecipientType } from "../../discussions/types/RecipientType";
import "./MiniAvatar.css";
import { CSSProperties, useMemo } from "react";
import { generateAvatarContent } from "../../profile/functions/generateAvatarContent";

interface MiniAvatarDiscussionsProps {
  src?: string;
  style?: CSSProperties;
  height?: string;
  className?: string;
  name?: string;
  userName?: string;
  characterType: RecipientType;
}

function MiniAvatar(props: MiniAvatarDiscussionsProps) {
  const size = props.height ?? "30px";
  const avatarStyle: CSSProperties = useMemo(
    () => ({
      ...props.style,
      height: size,
      width: size,
      borderRadius: "50%",
      flexShrink: "0",
      backgroundColor:
        props.characterType === RecipientType.User
          ? "var(--green-avatar)"
          : "var(--blue-avatar)",
      color: props.characterType === RecipientType.User ? "#000" : "#fff",
      fontSize: parseInt(size) * 0.4,
    }),
    [props.characterType, props.style, size]
  );

  return (
    <div style={avatarStyle} className="miniAvatar">
      {generateAvatarContent({
        avatarSrc: props.src,
        name: props.name,
        userName: props.userName,
      })}
    </div>
  );
}
export default MiniAvatar;
