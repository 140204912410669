import "./BrowseMyGroups.css";
import { BasicGroupInfoType } from "../../types/BasicGroupInfoType";
import { Link } from "react-router-dom";
import { useGetMyGroups } from "../../customHooks/useGetMyGroups";
import GroupInfoButton from "./GroupInfoButton";
import CreateNewGroupButton from "./CreateNewGroupButton";
import CircularProgress from "@mui/material/CircularProgress";

function BrowseMyGroups() {
  const {
    data,
    isError: ErrorFetchingGroups,
    isLoading: isLoadingGroups,
  } = useGetMyGroups({ searchPhrase: "" });

  if (ErrorFetchingGroups) {
    return (
      <div className="groups__box">
        <span>Something went wrong, please try again later.</span>
      </div>
    );
  } else if (isLoadingGroups) {
    return (
      <div className="groups__box">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="groupsContainer">
      <Link to="./Create" style={{ textDecoration: "none" }}>
        <CreateNewGroupButton />
      </Link>
      {data && data.groups.length > 0 ? (
        data.groups.map((group: BasicGroupInfoType) => (
          <Link
            to={`./${group.id}`}
            key={`link${group.id}`}
            style={{ textDecoration: "none", color: "transparent" }}
          >
            <GroupInfoButton
              groupName={group.name}
              avatar={group.avatar}
              key={group.id}
            />
          </Link>
        ))
      ) : (
        <div className="groups__infoMessage">
          <span>You don't have any groups yet</span>
        </div>
      )}
    </div>
  );
}

export default BrowseMyGroups;
