import MiniAvatar from "../../components/elements/MiniAvatar";
import { useResponsiveScreen } from "../../customHooks/useResponsiveScreen";
import { RecipientType } from "../../discussions/types/RecipientType";
import styles from "./GroupInfoButton.module.css";

export interface GroupInfoButtonProps {
  groupName: string;
  avatar?: string;
}

function GroupInfoButton(props: GroupInfoButtonProps) {
  const { isMobile } = useResponsiveScreen();
  
  return (
    <button className={styles.div}>
      <MiniAvatar
        height={isMobile ? "40px" : "45px"}
        src={props.avatar}
        name={props.groupName}
        characterType={RecipientType.Group}
      />
      <div className={styles.groupName}>{props.groupName}</div>
    </button>
  );
}

export default GroupInfoButton;
