import "./ContentCard.css";
import React, { useState } from "react";
import ReactionsPanel from "./ReactionsPanel";
import DiscussionsOverview from "../discussions/DiscussionsOverview";
import { useParams } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useFetchInfiniteFeed } from "./hooks/useFetchInfiniteFeed";
import { useSendStoryReaction } from "./hooks/useSendStoryReaction";
import { useDoubleTap } from "use-double-tap";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import DiscussionsOverviewDesktopView from "../discussions/DiscussionsOverviewDesktopView";

export interface ContentCardProps {
  children: React.ReactNode;
  storyId: string;
  storyDateId: string;
  favorite: boolean;
  bookmark: boolean;
  likes: number;
  pageNumber?: number;
  pullToRefetch?: boolean;
}

function ContentCard(props: ContentCardProps) {
  const { discussion } = useParams<{ discussion: "true" }>();
  const { isMobile } = useResponsiveScreen();

  const [displayDiscussions, setDisplayDiscussions] = useState<boolean>(
    discussion === "true"
  );

  const { refetch } = useFetchInfiniteFeed();

  const { mutate } = useSendStoryReaction({
    storyId: props.storyId,
    storyDateId: props.storyDateId,
    isFavorite: props.favorite,
    pageNumber: props.pageNumber,
  });

  const handleSendReactionOnDoubleTap = useDoubleTap(() => mutate());

  if (isMobile && displayDiscussions) {
    return (
      <div className="discussions_div">
        <DiscussionsOverview
          display={displayDiscussions}
          showDiscussionsView={setDisplayDiscussions}
          storyDateId={props.storyDateId}
          storyId={props.storyId}
        />
      </div>
    );
  }

  return (
    <>
      <div className="swipeable_wrapper" {...handleSendReactionOnDoubleTap}>
        <PullToRefresh
          isPullable={props.pullToRefetch ?? false}
          onRefresh={refetch}
          pullDownThreshold={50}
        >
          <>
            {props.children}
            <ReactionsPanel
              likesNumber={props.likes}
              favorite={props.favorite}
              bookmark={props.bookmark}
              id={props.storyId}
              dateId={props.storyDateId}
              showDiscussions={setDisplayDiscussions}
              pageNumber={props.pageNumber}
            />
          </>
        </PullToRefresh>
      </div>
      {!isMobile && displayDiscussions && (
        <DiscussionsOverviewDesktopView
          storyId={props.storyId}
          storyDateId={props.storyDateId}
          showDiscussionsView={setDisplayDiscussions}
        >
          {props.children}
        </DiscussionsOverviewDesktopView>
      )}
    </>
  );
}

export default ContentCard;
