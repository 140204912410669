import "./Feed.css";
import globalStyles from "../components/global.module.css";
import { useState } from "react";
import { useFilterTimeline } from "./hooks/useFilterTimeline";
import HeaderFeed from "./HeaderFeed";
import { ContentResponse } from "./types/ContentResponse";
import cardsRenderer from "./cardsRenderer";
import { InView } from "react-intersection-observer";
import ContentCard from "./ContentCard";
import { CircularProgress } from "@mui/material";
import { FilterStoriesType } from "./types/FilterStoriesType";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import { isWithinRenderRange } from "./hooks/functions/isWithinRenderRange";
import useActiveNews from "./hooks/useActiveNews";
import SwipeableCards from "./SwipeableCards";

interface FilterStoriesProps {
  filterType: FilterStoriesType;
}

function FilterStories({ filterType }: FilterStoriesProps) {
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  const { activeNewsIndex, activePageIndex, handleCardInView } =
    useActiveNews();

  const handleInViewChange = (inView: boolean, id: string) => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isError,
    isLoading,
    isFetchingNextPage,
  } = useFilterTimeline({ type: filterType, numberOfStories: 5 });

  const { isMobile } = useResponsiveScreen();

  return (
    <>
      {isMobile && <HeaderFeed />}
      {isLoading && !isFetched ? (
        <div className={globalStyles.messageInfo}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="feed" key={`feed`}>
            {!data || data.pages[0]?.Stories?.length === 0 ? (
              <div className={globalStyles.messageInfo}>{`You don't have any ${
                filterType === "Bookmark" ? "bookmarked" : "favorite"
              } news yet.`}</div>
            ) : (
              data.pages?.map((page, pageIndex) =>
                page?.Stories?.map((contentItemInput, contentIndex) => {
                  if (contentItemInput === null) return null;

                  const contentItem = contentItemInput as ContentResponse;

                  return (
                    <InView
                      key={`container-${contentItem.Id}`}
                      threshold={0.3}
                      onChange={(inView) => {
                        handleCardInView(inView, contentIndex, pageIndex);
                      }}
                    >
                      {({ ref }) => (
                        <div
                          ref={ref}
                          className="content_container"
                          key={`content-container-${contentItem.Id}`}
                        >
                          {contentIndex % 5 === 2 &&
                            !triggeringFetchIds.includes(contentItem.Id) && (
                              <InView
                                onChange={(inView) =>
                                  handleInViewChange(inView, contentItem.Id)
                                }
                              />
                            )}
                          {isWithinRenderRange(
                            contentIndex,
                            pageIndex,
                            activeNewsIndex,
                            activePageIndex
                          ) && (
                            <ContentCard
                              storyId={contentItem.Id}
                              storyDateId={contentItem.DateId}
                              favorite={contentItem.Favorite}
                              bookmark={contentItem.Bookmark}
                              likes={contentItem.Likes}
                              pageNumber={pageIndex}
                              key={`content-card-${contentItem.Id}`}
                            >
                              <SwipeableCards
                                storyId={contentItem.Id}
                                isActiveNews={contentIndex === activeNewsIndex}
                              >
                                {cardsRenderer(
                                  contentItem.Cards,
                                  contentItem.Id,
                                  contentItem.DateId,
                                  contentItem.Source
                                )}
                              </SwipeableCards>
                            </ContentCard>
                          )}
                        </div>
                      )}
                    </InView>
                  );
                })
              )
            )}

            <InView
              className="inView"
              style={{ height: "10px", width: "100%" }}
              onChange={(inView) => {
                if (inView && hasNextPage && !isFetchingNextPage) {
                  fetchNextPage();
                }
              }}
              threshold={0.1}
            ></InView>
            {isError ? (
              <div className="feed__centeredMessage">Error loading content</div>
            ) : isFetchingNextPage ? (
              <div className="feed__centeredMessage">
                <CircularProgress size={18} />
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}

export default FilterStories;
