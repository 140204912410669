import React, { useState } from "react";
import { useEffect, useRef } from "react";
import SwipeableViews from "react-swipeable-views";
import "./ContentCard.css";

interface SwipeableCardsProps {
  storyId: string;
  isActiveNews: boolean;
  children: React.ReactNode[];
}

function SwipeableCards({
  storyId,
  isActiveNews,
  children,
}: SwipeableCardsProps) {
  const [activeCardIndex, setActiveCardIndex] = useState(0);

  const handleChangeIndex = (index: number) => {
    setActiveCardIndex(index);
  };

  const activeCardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (activeCardRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];

          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            activeCardRef.current?.focus();
          }
        },
        { threshold: 1 }
      );

      observer.observe(activeCardRef.current);
      return () => observer.disconnect();
    }
  }, []);

  const switchCardsWithKeyArrows = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const totalIndexes = React.Children.count(children);
    if (event.key === "ArrowLeft" && activeCardIndex > 0) {
      handleChangeIndex(activeCardIndex - 1);
    } else if (
      event.key === "ArrowRight" &&
      activeCardIndex < totalIndexes - 1
    ) {
      handleChangeIndex(activeCardIndex + 1);
    }
  };

  const hasChildren = React.Children.count(children) > 0;

  if (!hasChildren) {
    return null;
  }

  return (
    <div
      ref={activeCardRef}
      tabIndex={0}
      onKeyDown={switchCardsWithKeyArrows}
      className={"swipeable_wrapper"}
    >
      <SwipeableViews
        index={activeCardIndex}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents={true}
        resistance={true}
        className="swipeable_card"
      >
        {isActiveNews
          ? children
          : children.map((child, index) =>
              index === 0 || index === 1 ? (
                child
              ) : (
                <div key={`hiden-card${storyId}${index}`}></div>
              )
            )}
      </SwipeableViews>
      <div className="dot__container">
        {children.map((dot, index) => (
          <div
            className={`dot ${index === activeCardIndex ? "dot__active" : ""}`}
            key={`${storyId}${index}_dot`}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default SwipeableCards;
