import axios, { AxiosRequestConfig } from "axios";
import { loginRequest } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { toast } from "react-toastify";
import { warningToastOptions } from "./components/toastOptions";

const { REACT_APP_API_URL } = process.env;

if (!REACT_APP_API_URL) {
  throw new Error("API URL is missing or empty");
}
const msalInstance = new PublicClientApplication(msalConfig);
const apiClient = axios.create({
  baseURL: REACT_APP_API_URL,
});

apiClient.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.headers = config.headers || {};
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    // config.headers["Authorization"] = "Bearer " + "";

    const account = msalInstance.getAllAccounts()[0];
    const request = {
      ...loginRequest,
      account: account,
    };
    if (account) {
      try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(
          request
        );
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] =
            "Bearer " + accessTokenResponse.accessToken;
        }
      } catch (error) {
        console.error(error);
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      if (!window.navigator.onLine) {
        toast.warn(
          "You are offline. Please check your internet connection.",
          warningToastOptions
        );
      }
      console.error(error);
    }
    return Promise.reject(error);
  }
);

export { msalInstance, apiClient };
