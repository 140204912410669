import { useMutation } from "react-query";
import { apiClient } from "../../apiClient";
import { AxiosError, AxiosResponse } from "axios";

interface PostSubscriptionTokenResponse {
  clientSecret: string;
}

export class NoPreviousSubscriptionError extends Error {
  code: string;

  constructor(code: string, message: string) {
    super(message);
    this.name = "NoPreviousSubscription";
    this.code = code;
  }
}

export const usePostSubscriptionToken = () => {
  const postSubscription = async (): Promise<PostSubscriptionTokenResponse> => {
    try {
      const respose: AxiosResponse<PostSubscriptionTokenResponse> =
        await apiClient.post("subscriptions/token");
      return respose.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          throw new NoPreviousSubscriptionError("404", "Not Found");
        }
      }
      throw error;
    }
  };

  return useMutation("postSubscriptionToken", postSubscription);
};
