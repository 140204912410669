import { stripeConfig } from "../customConfig";
import { SubscriptionType, useMySubscription } from "./hooks/useMySubscription";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./PricingPage.module.css";
import globalStyles from "../components/global.module.css";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { ReactComponent as PaperPlanesLogo } from ".././components/icons/PaperPlanes.svg";
import {
  NoPreviousSubscriptionError,
  usePostSubscriptionToken,
} from "./hooks/usePostSubscriptionToken";
import { useState, useEffect } from "react";

const PricingPage = () => {
  const {
    data: subscriptionData,
    isLoading: isLoadingSubscriptionStatus,
    error: errorLoadingSubscriptionStatus,
  } = useMySubscription();
  const {
    mutate: getSubscriptionToken,
    data: subscriptionToken,
    isLoading: isLoadingSubscriptionToken,
  } = usePostSubscriptionToken();
  const [tokenId, setTokenId] = useState<string>("");
  const [tokenIdError, setTokenIdError] = useState<boolean>(false);

  useEffect(() => {
    if (subscriptionData?.subscriptionType === SubscriptionType.Free) {
      getSubscriptionToken(undefined, {
        onError: (error) => {
          if (error instanceof NoPreviousSubscriptionError) {
            setTokenId("");
          } else {
            setTokenIdError(true);
          }
        },
      });
    }
  }, [getSubscriptionToken, subscriptionData]);

  useEffect(() => {
    if (subscriptionToken) {
      setTokenId(subscriptionToken.clientSecret);
    }
  }, [subscriptionToken]);

  if (
    isLoadingSubscriptionStatus ||
    isLoadingSubscriptionToken ||
    !subscriptionData?.userId
  ) {
    return (
      <div className={globalStyles.messageInfo}>
        <CircularProgress />
      </div>
    );
  }
  if (errorLoadingSubscriptionStatus || !subscriptionData || tokenIdError) {
    return (
      <div className={globalStyles.messageInfo}>
        Something went wrong. Please try again
      </div>
    );
  }
  if (subscriptionData.subscriptionType === SubscriptionType.Premium) {
    return (
      <div className={styles.alreadyPremium}>
        <PaperPlanesLogo className={styles.logo} />
        <span>
          You already have premium account
          <span>
            <WorkspacePremiumIcon />
          </span>
        </span>
      </div>
    );
  }

  return (
    <div className={styles.pricingList_container}>
      <stripe-pricing-table
        pricing-table-id={stripeConfig.pricingTableId}
        publishable-key={stripeConfig.publishableKey}
        client-reference-id={subscriptionData.userId}
        {...(tokenId && { "customer-session-client-secret": tokenId })}
      />
    </div>
  );
};

export default PricingPage;
