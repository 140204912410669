import "./Feed.css";
import { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";
import ContentCard from "./ContentCard";
import AnimationScreen from "../components/AnimationScreen";
import { ContentResponse } from "./types/ContentResponse";
import HeaderFeed from "./HeaderFeed";
import cardsRenderer from "./cardsRenderer";
import {
  ForbiddenTimelineError,
  useFetchInfiniteFeed,
} from "./hooks/useFetchInfiniteFeed";
import LastCard from "./LastCard";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import { isWithinRenderRange } from "./hooks/functions/isWithinRenderRange";
import useActiveNews from "./hooks/useActiveNews";
import { useMarkNewsAsRead } from "./hooks/useMarkNewsAsRead";
import { useIsAuthenticated } from "@azure/msal-react";
import SwipeableCards from "./SwipeableCards";

function Feed() {
  const [showEndingCard, setShowEndingCard] = useState(false);
  const [showGetPremiumCard, setShowGetPremiumCard] = useState(false);
  const [triggeringFetchIds, setTriggeringFetchIds] = useState<string[]>([]);

  const { activeNewsIndex, activePageIndex, handleCardInView } =
    useActiveNews();

  const isAuthenticated = useIsAuthenticated();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isError,
    error,
    isLoading,
    isFetchingNextPage,
  } = useFetchInfiniteFeed();

  const { mutate: markNewsAsRead } = useMarkNewsAsRead();

  const { isMobile } = useResponsiveScreen();

  const handleFetchNextPageOnInviewChange = (inView: boolean, id: string) => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      setTriggeringFetchIds((prevIds) => [...prevIds, id]);
    }
  };

  const handleMarkCardAsReadOnInviewChange = (
    inView: boolean,
    storyDateId: string,
    storyId: string
  ) => {
    if (inView && isAuthenticated) {
      markNewsAsRead({ storyDateId, storyId });
    }
  };

  useEffect(() => {
    if (error && error instanceof ForbiddenTimelineError) {
      setShowGetPremiumCard(true);
    }
  }, [error]);

  if (isLoading && !isFetched) {
    return <AnimationScreen loadingText="LOADING..." showTopMenu={true} />;
  }

  return (
    <>
      {isMobile && <HeaderFeed />}
      <div className="feed" key="feed">
        {data?.pages?.map((page, pageIndex) =>
          page?.Stories?.map((contentItemInput, contentIndex) => {
            if (!contentItemInput) return null;

            const contentItem = contentItemInput as ContentResponse;

            return (
              <InView
                key={`container-${contentItem.Id}`}
                threshold={0.3}
                onChange={(inView) => {
                  handleCardInView(inView, contentIndex, pageIndex);
                }}
              >
                {({ ref }) => (
                  <div ref={ref} className="content_container">
                    <InView
                      key={`inViewMarkAsRead-${contentItem.Id}`}
                      threshold={1}
                      triggerOnce={true}
                      className="feed__inviewMarkAsRead"
                      onChange={(inView) =>
                        handleMarkCardAsReadOnInviewChange(
                          inView,
                          contentItem.DateId,
                          contentItem.Id
                        )
                      }
                    />
                    {contentIndex % 5 === 2 &&
                      !triggeringFetchIds.includes(contentItem.Id) && (
                        <InView
                          key={`inViewHandleFetch-${contentItem.Id}`}
                          onChange={(inView) =>
                            handleFetchNextPageOnInviewChange(
                              inView,
                              contentItem.Id
                            )
                          }
                        />
                      )}

                    {isWithinRenderRange(
                      contentIndex,
                      pageIndex,
                      activeNewsIndex,
                      activePageIndex
                    ) && (
                      <ContentCard
                        storyId={contentItem.Id}
                        storyDateId={contentItem.DateId}
                        favorite={contentItem.Favorite}
                        bookmark={contentItem.Bookmark}
                        likes={contentItem.Likes}
                        pageNumber={pageIndex}
                        pullToRefetch={pageIndex === 0 && contentIndex === 0}
                        key={`content-card-${contentItem.Id}`}
                      >
                        <SwipeableCards
                          storyId={contentItem.Id}
                          isActiveNews={contentIndex === activeNewsIndex}
                        >
                          {cardsRenderer(
                            contentItem.Cards,
                            contentItem.Id,
                            contentItem.DateId,
                            contentItem.Source
                          )}
                        </SwipeableCards>
                      </ContentCard>
                    )}
                  </div>
                )}
              </InView>
            );
          })
        )}

        <InView
          className="inView"
          style={{ height: "10px", width: "100%" }}
          onChange={(inView) => {
            if (inView && hasNextPage && !isFetchingNextPage) {
              fetchNextPage();
            } else if (!hasNextPage) {
              setShowEndingCard(true);
            }
          }}
          threshold={0.1}
        />
        {showGetPremiumCard ? (
          <div className="content_container">
            <LastCard
              header="You have reached the limit of free news for today."
              additionalInfo="Upgrade your account to explore unlimited articles, enjoy premium features, and support the growth of our app."
              showPremiumButton={true}
            />
          </div>
        ) : showEndingCard ? (
          <div className="content_container">
            <LastCard
              header="There is no more content available for you now"
              additionalInfo="Please check back later"
            />
          </div>
        ) : isError ? (
          <div className="feed__centeredMessage">Error loading content</div>
        ) : isFetchingNextPage ? (
          <div className="feed__centeredMessage">Loading more content</div>
        ) : hasNextPage ? (
          <div className="feed__centeredMessage">Loading more...</div>
        ) : null}
      </div>
    </>
  );
}

export default Feed;
