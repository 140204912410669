import globalStyles from "../components/global.module.css";
import styles from "./TopDailyNews.module.css";
import "./Feed.css";
import { CircularProgress } from "@mui/material";
import cardsRenderer from "./cardsRenderer";
import ContentCard from "./ContentCard";
import { useGetTopDailyNews } from "./hooks/useGetTopDailyNews";
import { ContentResponse } from "./types/ContentResponse";
import LastCard from "./LastCard";
import Menu from "../components/Menu";
import { useIsAuthenticated } from "@azure/msal-react";
import HeaderFeed from "./HeaderFeed";
import useCalculateContentHeight from "../customHooks/useCalculateContentHeight";
import useActiveNews from "./hooks/useActiveNews";
import { InView } from "react-intersection-observer";
import { isWithinRenderRange } from "./hooks/functions/isWithinRenderRange";
import SwipeableCards from "./SwipeableCards";

function TopDailyNews() {
  const { data, isLoading, isError } = useGetTopDailyNews();
  const isAuthenticated = useIsAuthenticated();

  const { activeNewsIndex, handleCardInView } = useActiveNews();

  const contentHeight = useCalculateContentHeight();

  if (isLoading) {
    return (
      <div className={globalStyles.messageInfo}>
        <CircularProgress />
      </div>
    );
  }
  if (isError) {
    return (
      <div className={globalStyles.messageInfo}>
        Something went wrong. Please refresh the page
      </div>
    );
  }

  return (
    <div className={styles.containerAll}>
      <HeaderFeed />
      <div style={{ height: contentHeight }}>
        <div className={styles.topFeed}>
          {data?.Stories?.map((story: ContentResponse, storyIndex) => {
            if (story === null) return null;

            return (
              <InView
                key={`container-${story.Id}`}
                threshold={0.3}
                onChange={(inView) => {
                  handleCardInView(inView, storyIndex, 0);
                }}
              >
                {({ ref }) => (
                  <div
                    ref={ref}
                    className="content_container"
                    key={story.Id + "content_container"}
                  >
                    {isWithinRenderRange(storyIndex, 0, activeNewsIndex, 0) && (
                      <ContentCard
                        storyId={story.Id}
                        storyDateId={story.DateId}
                        favorite={story.Favorite}
                        bookmark={story.Bookmark}
                        likes={story.Likes}
                        key={`content-card-${story.Id}`}
                      >
                        <SwipeableCards
                          storyId={story.Id}
                          isActiveNews={storyIndex === activeNewsIndex}
                        >
                          {cardsRenderer(
                            story.Cards,
                            story.Id,
                            story.DateId,
                            story.Source
                          )}
                        </SwipeableCards>
                      </ContentCard>
                    )}
                  </div>
                )}
              </InView>
            );
          })}
          {!isAuthenticated && (
            <div className="content_container">
              <LastCard
                header="Sign in to see more!"
                additionalInfo=""
                showSignInButton={true}
              />
            </div>
          )}
        </div>
      </div>
      {!isAuthenticated && <Menu />}
    </div>
  );
}

export default TopDailyNews;
