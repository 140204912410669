import styles from "./ReferralRulesView.module.css";
import { CheckCircle } from "lucide-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ReactComponent as ArrowBackIcon } from "../components/icons/ArrowBackIcon.svg";
import { useCopyToClipboard } from "../customHooks/useCopyToClipboard";
import { SubscriptionType, useMySubscription } from "./hooks/useMySubscription";
import globalStyles from "../components/global.module.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ReferralRulesView() {
  const { isCopied, copyTextToClipboard } = useCopyToClipboard();
  const { data, isError, isLoading } = useMySubscription();

  const navigate = useNavigate();

  const handleArrowBackClick = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <div className={globalStyles.messageInfo}>
        <CircularProgress size={14} />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={globalStyles.messageInfo}>
        Something went wrong. Please try again
      </div>
    );
  }

  if (!data) {
    return null;
  }

  if (data.subscriptionType === SubscriptionType.Free) {
    return (
      <div className={globalStyles.messageInfo}>
        Referral program is only available for members with Premium
        subscription.
      </div>
    );
  }

  return (
    <div className={styles.background}>
      <div className={styles.arrowBack} onClick={handleArrowBackClick}>
        <ArrowBackIcon />
      </div>

      <div className={styles.card}>
        <h1>Referral Program Rules</h1>
        <div className={styles.cardContent}>
          <div className={styles.listItem}>
            <CheckCircle size={22} className={styles.icon} />
            <p>Works only when you have Premium.</p>
          </div>
          <div className={styles.listItem}>
            <CheckCircle size={20} className={styles.icon} />
            <p>Each referral gives you 2 months pause in payments.</p>
          </div>
          <div className={styles.listItem}>
            <CheckCircle size={22} className={styles.icon} />
            <p>New user gets 1 month free.</p>
          </div>
        </div>
      </div>

      <div className={styles.card}>
        <h2>Your Referral Code</h2>
        <div className={styles.cardContent}>
          <div className={styles.codeContainer}>
            <span>{data.referralCode}</span>
            <button onClick={() => copyTextToClipboard(data.referralCode)}>
              <ContentCopyIcon fontSize="inherit" />
            </button>
          </div>
          {isCopied && <p className={styles.copied}>Copied!</p>}
        </div>
      </div>
    </div>
  );
}

export default ReferralRulesView;
