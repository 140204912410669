import { useState } from "react";

function useActiveNews() {
  const [activeNewsIndex, setActiveNewsIndex] = useState<number>(0);
  const [activePageIndex, setActivePageIndex] = useState<number>(0);

  const handleCardInView = (
    inView: boolean,
    currentNewsIndex: number,
    currentPageIndex: number
  ) => {
    if (inView) {
      setActiveNewsIndex(currentNewsIndex);
      setActivePageIndex(currentPageIndex);
    }
  };

  return {
    activeNewsIndex,
    activePageIndex,
    handleCardInView,
  };
}
export default useActiveNews;
