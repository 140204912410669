import "./Contact.css";
import { ReactElement } from "react";
import MiniAvatar from "./MiniAvatar";
import { RecipientType } from "../../discussions/types/RecipientType";
import { useResponsiveScreen } from "../../customHooks/useResponsiveScreen";

export interface ContactProps {
  Name: string;
  UserName: string;
  ContactType: RecipientType;
  Avatar?: string;
  children?: ReactElement;
  onClick?: () => void;
}

function Contact(props: ContactProps) {
  const { isMobile } = useResponsiveScreen();

  return (
    <div className="contact__div">
      <div className="contact__infoDiv" onClick={props.onClick}>
        <MiniAvatar
          height={isMobile ? "40px" : "45px"}
          src={props.Avatar}
          name={props.Name}
          userName={props.UserName}
          characterType={props.ContactType}
        />
        <div className="contact__nameContainer">
          <div className="contact__name">{props.Name}</div>
          {props.UserName ? (
            <div className="contact__UserName">{`(@${props.UserName})`}</div>
          ) : null}
        </div>
      </div>
      {props.children ? (
        <div className="contact__children">{props.children}</div>
      ) : null}
    </div>
  );
}

export default Contact;
