import styles from "./GroupOverview.module.css";
import globalStyles from "../../components/global.module.css";
import { useState } from "react";
import AddContactsModal from "./AddContactsModal";
import GroupMembersList from "./GroupMembersList";
import { useGetGroupDetails } from "../../customHooks/useGetGroupDetails";
import { ReactComponent as ContactIcon } from "../../components/icons/ContactIcon.svg";
import GroupDetailsHeader from "./GroupDetailsHeader";
import CircularProgress from "@mui/material/CircularProgress";
import { useAddNewMembersToGroup } from "../../customHooks/useAddNewMembersToGroup";
import { toast } from "react-toastify";
import { errorToastOptions } from "../../components/toastOptions";

interface GroupOverviewProps {
  groupId: string;
}

function GroupOverview({ groupId }: GroupOverviewProps) {
  const [addNewContactsView, setAddNewContactsView] = useState<boolean>(false);

  const {
    data: groupData,
    isLoading: isLoadingGroupData,
    isError: isErrorFetchingGroupData,
  } = useGetGroupDetails({ groupId });

  const { mutate: addNextContacts, isLoading: isAddingContacts } =
    useAddNewMembersToGroup({ groupId: groupId });

  const handleAddMembers = (memebrs: string[]) => {
    addNextContacts(memebrs, {
      onSuccess: () => setAddNewContactsView(false),
      onError: (error) => {
        toast.error(
          "Something went wrong. We couldn't add new members to the group",
          errorToastOptions
        );
      },
    });
  };

  return (
    <>
      {isLoadingGroupData ? (
        <div className={globalStyles.messageInfo}>
          <CircularProgress />
        </div>
      ) : isErrorFetchingGroupData ? (
        <div className={globalStyles.messageInfo}>
          <span>An error occured.</span>
        </div>
      ) : groupData ? (
        <>
          <div className={styles.background}>
            <div className={styles.overview}>
              <div className={styles.header}>
                <GroupDetailsHeader
                  groupId={groupId}
                  isOwner={groupData.isOwner}
                  groupName={groupData.group.name}
                  avatar={groupData.group.avatar}
                  membersCount={groupData.group.groupMembers.length}
                />
              </div>
              {groupData && groupData.isOwner && (
                <button
                  className={styles.addNewUser}
                  onClick={() => setAddNewContactsView(true)}
                >
                  <ContactIcon fill="#0F5940" height={"26px"} width={"26px"} />
                  <span>Add User</span>
                </button>
              )}
              <div className={styles.membersList}>
                <GroupMembersList
                  groupMembers={groupData.group.groupMembers}
                  isOwner={groupData.isOwner}
                  groupOwner={groupData.group.groupOwner}
                  groupId={groupData.group.id}
                />
              </div>
            </div>
          </div>
          {addNewContactsView ? (
            <AddContactsModal
              onArrowBackClick={() => setAddNewContactsView(false)}
              currentMembers={groupData.group.groupMembers}
              handleAddClick={handleAddMembers}
              addButtonText={
                isAddingContacts ? "Adding contacts..." : "Add contacts"
              }
              addButtonDisabled={isAddingContacts}
              display={addNewContactsView}
              showArrowBackIcon={true}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default GroupOverview;
