import globalStyles from "../components/global.module.css";
import "./Feed.css";
import ContentCard from "./ContentCard";
import { useParams } from "react-router-dom";
import HeaderFeed from "./HeaderFeed";
import useCalculateContentHeight from "../customHooks/useCalculateContentHeight";
import CircularProgress from "@mui/material/CircularProgress";
import cardsRenderer from "./cardsRenderer";
import { useFetchSingleStory } from "./hooks/useFetchSingleStory";
import axios from "axios";
import Menu from "../components/Menu";
import { useIsAuthenticated } from "@azure/msal-react";
import { useResponsiveScreen } from "../customHooks/useResponsiveScreen";
import SwipeableCards from "./SwipeableCards";

function SingleStory() {
  const { newsDate, newsId } = useParams();
  const contentHeight = useCalculateContentHeight();

  const { data, isLoading, isError, error } = useFetchSingleStory({
    storyDateId: newsDate,
    storyId: newsId,
  });

  const isAuthenticated = useIsAuthenticated();

  const { isMobile } = useResponsiveScreen();

  return (
    <div
      style={{ height: isMobile ? contentHeight : "100%" }}
      className="singleStoryContainer"
    >
      {isMobile && <HeaderFeed />}
      {isLoading ? (
        <div className={globalStyles.messageInfo}>
          <CircularProgress />
        </div>
      ) : axios.isAxiosError(error) && error.response?.status === 404 ? (
        <div className={globalStyles.messageInfo}>
          News you are trying to access does not exist
        </div>
      ) : isError ? (
        <div className={globalStyles.messageInfo}>
          <p>Something went wrong</p>
        </div>
      ) : (
        <>
          {data ? (
            <div
              className="singleStoryContentContainer"
              key={`content-container-${data.Id}`}
            >
              <ContentCard
                storyId={data.Id}
                storyDateId={data.DateId}
                key={`content-card-${data.Id}`}
                favorite={data.Favorite}
                bookmark={data.Bookmark}
                likes={data.Likes}
              >
                <SwipeableCards storyId={data.Id} isActiveNews={true}>
                  {cardsRenderer(data.Cards, data.Id, data.DateId, data.Source)}
                </SwipeableCards>
              </ContentCard>
            </div>
          ) : null}
        </>
      )}
      {!isAuthenticated && isMobile && <Menu />}
    </div>
  );
}

export default SingleStory;
